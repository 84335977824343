/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function($) {
	
	// Remove empty P tags created by WP inside of Accordion and Orbit
	$('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	$('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( $(this).innerWidth() / $(this).innerHeight() > 1.5 ) {
			$(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
			$(this).wrap("<div class='responsive-embed'/>");
		}
	});

	//Notification Banner with close option 
	$(".close-notification-bar").on("click", () => {
		//log output
		//console.log("click registered");
		$(".notification-bar").hide( "slow" ); 
		//log output
		console.log("closed notification bar");
	});

	//show more button
	$( ".showMore" ).click(function(event) {
	event.preventDefault();
	$( ".show-hide-content" ).slideToggle( "slow" );
		var $this = $(this);
		$this.toggleClass("open");
	
		if ($this.hasClass("open")) {
		  $this.html("Show Less");
		} else {
		  $this.html("Show More");
		}
	});

	// testing mega menu helper
	// $( document ).ready(function( ) {
	// 	$('nav .megamenu').find('ul.sub-menu').first().wrap('<div class="sm-container" />');
	// });

}); 

